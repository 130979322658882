/* sign in FORM */

html {
    background: #e6e6e5;
}

/* 9bbdff */
#logreg-forms {
    border-radius     : 30px !important;
    width: 412px;
    background-color: #ececec;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.12), 0 1px 20px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

#logreg-forms form {
    width: 100%;
    max-width: 410px;
    padding: 15px;
    margin: auto;
}

#logreg-forms .form-control {
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 10px;
    font-size: 16px;
}

#logreg-forms .form-control:focus {
    z-index: 2;
}

#logreg-forms .form-signin input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

#logreg-forms .form-signin input[type="password"] {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

#logreg-forms .social-login {
    width: 390px;
    margin: 0 auto;
    margin-bottom: 14px;
}

#logreg-forms .social-btn {
    font-weight: 100;
    color: white;
    width: 190px;
    font-size: 0.9rem;
}

#logreg-forms a {
    display: block;
    padding-top: 10px;
    color: lightseagreen;
}

#logreg-form .lines {
    width: 200px;
    border: 1px solid red;
}

#logreg-forms button[type="submit"] {
    margin-top: 10px;
}

#logreg-forms .facebook-btn {
    background-color: #3C589C;
}

#logreg-forms .google-btn {
    background-color: #DF4B3B;
}

#logreg-forms .form-signup .social-btn {
    width: 210px;
}

#logreg-forms .form-signup input {
    margin-bottom: 2px;
}

.form-signup .social-login {
    width: 210px !important;
    margin: 0 auto;
}


/* Mobile */

@media screen and (max-width:500px) {
    #logreg-forms {
        width: 100%;
    }

    #logreg-forms .social-login {
        width: 200px;
        margin: 0 auto;
        margin-bottom: 10px;
    }

    #logreg-forms .social-btn {
        font-size: 1.3rem;
        font-weight: 100;
        color: white;
        width: 200px;
        height: 56px;
    }

    #logreg-forms .social-btn:nth-child(1) {
        margin-bottom: 5px;
    }

    #logreg-forms .social-btn span {
        display: none;
    }

    #logreg-forms .facebook-btn:after {
        content: 'Facebook';
    }

    #logreg-forms .google-btn:after {
        content: 'Google+';
    }
}

.logo {
    /* width: 150px; */
}

.backLogin {
    background-image   : url('https://xam-app.s3.us-west-2.amazonaws.com/images/simplesuit/bg-figuras-ss.svg');
    background-repeat  : no-repeat;
    background-position: center center;
    background-size    : contain;
    position           : absolute;
    height             : 100%;
    width              : 100%;
    top                : 0;
    /* background: rgb(153, 193, 255);
    background: linear-gradient(0deg, rgba(153, 193, 255, 1) 0%, rgba(199, 100, 249, 1) 100%); */
}

.backOops {
    background: rgb(153, 193, 255);
    background: linear-gradient(0deg, rgba(153, 193, 255, 1) 0%, rgba(199, 100, 249, 1) 100%);
}

p,
li,
a {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: #445544;
    font-size: 14px;
}

.iconFooter {
    color: #445544;
    font-size: 25px;
}

.menubottom li a {
    font-size: 12px;
}

.redesFooter li a {
    font-size: 16px;
    color: #445544;
}

.legals small {
    font-size: 11px;
    color: #73277C;
}

.legals a {
    font-size: 11px;
    color: #73277C;
}

@media all and (orientation: portrait) {
    .logoFooter img {
        width: 115px;
    }
}

.switch {
    transform: scale(.75);
    position: relative;
    display: inline-block;
    width: 56px;
    height: 28px;
    margin-bottom: -7px;
    margin-left: -7px;
}

.switch input {
    display: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #007bff;
}

input:focus+.slider {
    box-shadow: 0 0 1px #c3c3c3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}


/* Rounded sliders */

.slider.round-in {
    border-radius: 25px;
}

.slider.round-in:before {
    border-radius: 50%;
}

.modalTerms {
    max-width: 1300px !important;
}

.modal-dialog,
.modal-content {
    /* 80% of window height */
    height: 90%;
}

.modal-body {
    /* 100% = dialog height, 120px = header + footer */
    max-height: calc(100% - 120px);
    overflow-y: scroll;
}

.modal-backdrop {
    opacity: 0.87 !important;
}

#termsModal .close {
    font-size: 43px;
}


/*************************************************************************************
 * @name:       enlivenem.css
                the part of Enliven'em script
 * @version:    1.8
 * @URL:        https://enlivenem.com
 * @copyright:  (c) 2014-2019 DeeThemes (https://codecanyon.net/user/DeeThemes)
 * @licenses:   https://codecanyon.net/licenses/regular
                https://codecanyon.net/licenses/extended
*************************************************************************************/

.formLogin {
    position: absolute;
    top: 0;
}

.elvn-responsive {
    height: 100vh !important;
    padding-bottom: 0% !important;
}

.heightLogin {
    height: 90vh;
}

@media (min-width: 1280px) {
    .enlivenem {
        top: -150px !important;
    }
}

.enlivenem {
    top: -70px !important;
}

@media (max-width: 688px) {
    .enlivenem {
        top: -20px !important;
    }
}

/* @media (max-width: 768px) {
    .animacionLoginDesktop {
        display: none !important;
    }
} */


/* Menu  */

.nav .open>a,
.nav .open>a:hover,
.nav .open>a:focus {
    background-color: transparent;
}


/*-------------------------------*/


/*           Wrappers            */


/*-------------------------------*/


/* // #wrapper {
//     padding-left: 0;
//     -webkit-transition: all 0.5s ease;
//     -moz-transition: all 0.5s ease;
//     -o-transition: all 0.5s ease;
//     transition: all 0.5s ease;
// } */

#wrapper.toggled {
    padding-left: 320px;
    background: rgba(0, 0, 0, 0) !important;
}

#sidebar-wrapper #wrapper.toggled {
    overflow-y: auto;
}

#wrapper.toggled .tittleDash {
    margin-top: 30px !important;
}

.tittleDash {
    margin-top: 40px !important;
}

#sidebar-wrapper {
    z-index: 13;
    left: -385px;
    position: fixed;
    height: 100%;
    width: 0;
    /*
    // margin-left: -220px; */
    /* overflow-y: auto; */
    display: block;
    background: #ffffff !important;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

@media (max-width: 567px) {
    #wrapper.toggled .sidebar-nav {
        top: 0px !important;
        width: 100% !important;
    }

    #wrapper.toggled #sidebar-wrapper {
        width: 100% !important;
    }

    .hamburger {
        position: absolute;
        right: 27px !important;
        top: 70px !important;
    }

    #wrapper.toggled .hamburger {
        position: absolute;
        right: -5px !important;
        top: -33px !important;
    }

    #wrapper.toggled {
        padding-left: 0;
    }
}

.pagination li {
    font-size: 30px !important;
}

.pagination a {
    padding: 1px 6px 3px 6px;
}

@media (max-width: 768px) {
    .logOffTopM {
        padding-right: 62px;
    }

    #wrapper.toggled .sidebar-nav {
        top: -7px;
        width: 100% !important;
    }

    #wrapper.toggled #sidebar-wrapper {
        width: 100% !important;
    }

    .hamburger {
        position: absolute;
        left: 0px !important;
        top: 1px !important;
    }

    #wrapper.toggled .hamburger {
        position: absolute !important;
        /* right: -5px !important; */
        top: 13px !important;
        left: 80% !important;
        color: #790d79 !important;
    }

    #wrapper.toggled {
        padding-left: 0;
    }

    #navTop {
        display: none !important;
    }

    .navLogo {
        display: block !important;
    }
}

#sidebar-wrapper::-webkit-scrollbar {
    display: none;
}

#wrapper.toggled #sidebar-wrapper {
    left: 0px;
    top: 0;
    width: 270px;
    overflow-y: auto;
    border-radius: 0px 15px 15px 0;
    /* -webkit-box-shadow: -3px 7px 14px 1px #4A4A4A;
    box-shadow: -3px 7px 14px 1px #4A4A4A; */
}

#page-content-wrapper {
    width: 100%;
    /* // padding-top: 70px; */
}

#wrapper.toggled #page-content-wrapper {
    position: absolute !important;
    margin-right: -220px !important;
    padding-left: 0px;
    height: 100%;
}


/*-------------------------------*/


/*     Sidebar nav styles        */


/*-------------------------------*/

.sidebar-nav {
    position: relative;
    top: 7%;
    width: 258px;
    margin: 0;
    padding: 0;
    list-style: none;
}

.sidebar-nav li {
    position: relative;
    line-height: 25px;
    height: 50px;
    display: inline-block;
    width: 100%;
    margin: 0 0 6px 0;
}

.sidebar-nav li:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    height: 100%;
    width: 3px;
    background-color: #1c1c1c;
    -webkit-transition: width .2s ease-in;
    -moz-transition: width .2s ease-in;
    -ms-transition: width .2s ease-in;
    transition: width .2s ease-in;
}

.sidebar-nav li.sidebar-brand {
    color: #fff;
    background-color: #fff;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

.sidebar-nav li:first-child a {
    padding: 0px 42px 0px 16px;
}

.menuSide {
    color: #999999;
    position: absolute;
    left: 0px;
    top: -40px;
}

#wrapper.toggled .menuSide {
    color: #790d79;
}

.sidebar-nav li .subMenuPrincipal li a {
    padding: 0px 42px 0px 16px;
}

.sidebar-nav li:before {
    background-color: #158ACB;
}

.sidebar-nav li:hover:before,
.sidebar-nav li.open:hover:before {
    width: 100%;
    -webkit-transition: width .2s ease-in;
    -moz-transition: width .2s ease-in;
    -ms-transition: width .2s ease-in;
    transition: width .2s ease-in;
}

.sidebar-nav li a {
    display: block;
    color: #ddd;
    text-decoration: none;
    padding: 10px 15px 10px 30px;
}

.sidebar-nav li a:hover,
.sidebar-nav li a:active,
.sidebar-nav li a:focus,
.sidebar-nav li.open a:hover,
.sidebar-nav li.open a:active,
.sidebar-nav li.open a:focus {
    color: #fff !important;
    text-decoration: none;
    background-color: transparent;
}

.sidebar-nav>.sidebar-brand {
    height: 65px;
    font-size: 20px;
    line-height: 44px;
}

.sidebar-nav .dropdown-menu {
    position: relative;
    width: 100%;
    padding: 0;
    margin: 0;
    border-radius: 0;
    border: none;
    background-color: #222;
    box-shadow: none;
}


/*-------------------------------*/


/*       Hamburger-Cross         */


/*-------------------------------*/

#wrapper.toggled .hamburger {
    position: absolute;
    top: 12px;
    left: 254px;
    z-index: 999;
    display: block;
    width: 56px;
    height: 56px;
    margin-left: 15px;
    /* background: #999999; */
    border: none;
    font-size: 25px;
}

.navLogo {
    display: none;
}

.hamburger {
    position: fixed;
    top: 1px;
    font-size: 25px;
    z-index: 999;
    display: block;
    width: 32px;
    height: 32px;
    margin-left: 15px;
    background: transparent;
    border: none;
}

.hamburger:hover,
.hamburger:focus,
.hamburger:active {
    outline: none;
}

.hamburger.is-closed:before {
    content: '';
    display: block;
    width: 100px;
    font-size: 14px;
    color: #fff;
    line-height: 32px;
    text-align: center;
    opacity: 0;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    transition: all .35s ease-in-out;
    -webkit-transition: all .35s ease-in-out;
}

.hamburger.is-closed:hover:before {
    opacity: 1;
    display: block;
    transform: translate3d(-100px, 0, 0);
    transition: all .35s ease-in-out;
    -webkit-transform: translate3d(-100px, 0, 0);
    -webkit-transition: all .35s ease-in-out;
}

.hamburger.is-closed .hamb-top,
.hamburger.is-closed .hamb-middle,
.hamburger.is-closed .hamb-bottom,
.hamburger.is-open .hamb-top,
.hamburger.is-open .hamb-middle,
.hamburger.is-open .hamb-bottom {
    position: absolute;
    left: 0;
    height: 4px;
    width: 100%;
}

.hamburger.is-closed .hamb-top,
.hamburger.is-closed .hamb-middle,
.hamburger.is-closed .hamb-bottom {
    background-color: #999999;
}

.hamburger.is-closed .hamb-top {
    top: 5px;
    transition: all .35s ease-in-out;
    -webkit-transition: all .35s ease-in-out;
}

.hamburger.is-closed .hamb-middle {
    top: 50%;
    margin-top: -2px;
}

.hamburger.is-closed .hamb-bottom {
    bottom: 5px;
    transition: all .35s ease-in-out;
    -webkit-transition: all .35s ease-in-out;
}

.hamburger.is-closed:hover .hamb-top {
    top: 0;
    transition: all .35s ease-in-out;
    -webkit-transition: all .35s ease-in-out;
}

.hamburger.is-closed:hover .hamb-bottom {
    bottom: 0;
    transition: all .35s ease-in-out;
    -webkit-transition: all .35s ease-in-out;
}

.hamburger.is-open .hamb-top,
.hamburger.is-open .hamb-middle,
.hamburger.is-open .hamb-bottom {
    background-color: #999999;
}

.hamburger.is-open .hamb-top,
.hamburger.is-open .hamb-bottom {
    top: 50%;
    margin-top: -2px;
}

.hamburger.is-open .hamb-top {
    transform: rotate(45deg);
    transition: -webkit-transform .2s cubic-bezier(.73, 1, .28, .08);
    -webkit-transform: rotate(45deg);
    -webkit-transition: -webkit-transform .2s cubic-bezier(.73, 1, .28, .08);
}

.hamburger.is-open .hamb-middle {
    display: none;
}

.hamburger.is-open .hamb-bottom {
    transform: rotate(-45deg);
    transition: -webkit-transform .2s cubic-bezier(.73, 1, .28, .08);
    -webkit-transform: rotate(-45deg);
    -webkit-transition: -webkit-transform .2s cubic-bezier(.73, 1, .28, .08);
}

.hamburger.is-open:before {
    content: '';
    display: block;
    width: 100px;
    font-size: 14px;
    color: #fff;
    line-height: 32px;
    text-align: center;
    opacity: 0;
    transform: translate3d(0, 0, 0);
    transition: all .35s ease-in-out;
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-transition: all .35s ease-in-out;
}

.hamburger.is-open:hover:before {
    opacity: 1;
    display: block;
    transform: translate3d(-100px, 0, 0);
    transition: all .35s ease-in-out;
    -webkit-transform: translate3d(-100px, 0, 0);
    -webkit-transition: all .35s ease-in-out;
}


/*-------------------------------*/


/*            Overlay            */


/*-------------------------------*/

.overlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(250, 250, 250, .8);
    z-index: 1;
}

.subMenuPrincipal {
    width: 102% !important;
    top: 100% !important;
    left: -3px !important;
    margin-top: -59px !important;
    background: #e6e6e5 !important;
    padding: 16px !important;
}

.sidebar-nav .subMenuPrincipal li {
    height: 25px !important;
}

.subMenuPrincipal li a {
    color: #999999 !important;
}

.subMenuPrincipal li a:hover {
    color: #fff !important;
}

#wrapper.toggled #page-content-wrapper {
    width: 100%;
    background: #e6e6e5;
    /* padding-left: 90px; */
    display: contents;
}

@media (max-width: 1550px) {
    #wrapper.toggled #page-content-wrapper {
        width: 83%;
        background: #e6e6e5;
    }
}

.subMenuPrincipal li:before {
    background-color: #158ACB;
    height: 25px !important;
}

.sidebar-nav .subMenuPrincipal li:first-child a:hover {
    color: #fff;
    background-color: #158ACB !important;
}

.MBP_SecIzq {
    padding-left: 45px;
}

#wrapper.toggled .MBP_SecIzq {
    padding-left: 65px;
}

.btnMnu .card {
    /* width: 100px;
    height: 100px; */
    /* background: rgba(52, 52, 103, .6); */
    background: none;
    border: none;
}

.btnMnu .card:hover {
    /* width: 100px;
    height: 100px; */
    background: rgba(52, 52, 103, .9);
    opacity: 0.8;
}

.btnMnu .card:hover p {
    color: #ffffff;
}

.backgroundMenu {
    background: rgba(52, 52, 103, .9);
    border-radius: 5px;
}

.backgroundMenu p {
    color: #ffffff !important;
}

.btnMnu a {
    text-decoration: none;
    color: #fff;
}

.btnMnu a:hover {
    text-decoration: none;
    color: rgb(241, 241, 241);
}

.btnMnu i {
    font-size: 18px;
}

.btnMnu p {
    font-size: 12px;
    font-weight: 300;
}

.cardsMenu {
    justify-content: start;
}

.iconColor {
    margin: 0 auto;
    border-radius: 50%;
    line-height: 37px;
}


/* .logoSide {
    background: rgba(250, 250, 250, .8);
} */

.navbar {
    padding-top: 0rem !important;
}

.tittleMenu {
    color: #790d79;
    font-weight: 600;
}

body {
    background-image : url('https://xam-app.s3.us-west-2.amazonaws.com/images/simplesuit/fondo-ss.svg');
    background-repeat: no-repeat;
    background-size  : cover;
    font-family      : "Montserrat", sans-serif;
    padding          : 0;
    margin           : 0;
    min-height: 100vh;
    /* overflow-x: hidden; */
    /* background: linear-gradient(to bottom left, #C567F9, #9CBDFF);
    min-height: 100vh; */
}

.backgroundAnima {
    background-image   : url('https://xam-app.s3.us-west-2.amazonaws.com/images/simplesuit/bg-lineas-simple-suite.svg');
    background-repeat  : no-repeat;
    background-position: center center;
    background-size    : cover;
    min-height         : 100vh;
    width              : 100%;
    /* position: fixed;
    top: 0;
    height: 100vh;
    color: #fff;
    display: flex;
    flex-direction: column;
    text-align: center; */
}

.font-18 {
    font-size: 18px;
}

.scrollBars {
    display: none;
}

.form-control {
    height: 30px !important;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
    height: 30px !important;
    border: 1px solid #483871;
    padding: .175rem 1.75rem .175rem .75rem;
    width: 250px;
}

.btn {
    height: 30px;
    padding: .075rem .75rem;
}

.resultsOf {
    font-size: 0.9rem;
    font-weight: 400;
}

.resultsOf b {
    font-weight: 600;
}

html body .p-10 {
    padding: 10px;
}

html body .bg-info {
    background-color: #483871 !important;
}

html body .m-b-0 {
    margin-bottom: 0px;
}

.box {
    border-radius: 4px;
    padding: 10px;
    line-height: 12px;
    font-size: 1.1rem;
}

html body .m-l-10 {
    margin-left: 10px;
}

.textCard h4 {
    line-height: 11px;
    font-size: 1rem;
    font-weight: 400;
}

.textCard h5 {
    line-height: 10px;
    font-size: .9rem;
    font-weight: 400;
    padding-top: 3px;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #73277C;
    background-color: #fff;
    border-color: #73277C #73277C #fff;
}

.generalTab h5 {
    line-height: 10px;
    font-size: .9rem;
    font-weight: 400;
    padding-top: 3px;
}

.generalTab .card-subtitle {
    font-weight: 300;
    margin-bottom: 15px;
    color: #90a4ae;
}

.generalTab h6 {
    line-height: 9px;
    font-size: .85rem;
    font-weight: 400;
}

.generalTab .btn-secondary {
    background: #2980B9 !important;
    border: 1px solid #2980B9;
    color: #ffffff !important;
}

html body .m-t-15 {
    margin-top: 15px;
}

.generalTab h4 {
    line-height: 11px;
    font-size: 1rem;
    font-weight: 400;
}

.generalTab label {
    font-size: 13px;
}

.generalTab th {
    font-size: 13px;
    background: #73277C;
    color: #ffffff;
}

input[type=text] {
    border: 1px solid #483871;
    color: #a2a1a1;
    width: 250px;
}

input[type=email] {
    border: 1px solid #483871;
    color: #a2a1a1;
    width: 250px;
}

input[type=number] {
    border: 1px solid #483871;
    color: #a2a1a1;
    width: 250px;
}

.labelActive {
    font-size: 13px;
}

.tittlePage {
    font-size: 1.5rem !important;
    color: #212952;
    font-weight: 600 !important;
    padding-bottom: 7px;
    padding-left: 7px;
    padding-right: 40px;
    border-bottom: 2px solid #E45524;
}

textarea.form-control {
    height: 78px !important;
}

.cardUsers .card {
    min-height: 72px;
}

.cardUsers .bg-info {
    min-height: 72px;
}

.bg-card {
    max-width: 350px;
    width: 350px;
}

.changePass .btn-secondary {
    background: none !important;
    color: #73277C !important;
    border-color: #73277C !important;
    padding: 0px 14px 0px 10px !important;
    font-size: 13px !important;
}

.invalid {
    width: 100%;
    margin-top: .25rem;
    font-size: .875em;
    color: #dc3545;
}

.changePass {
    width: 320px;
}

.h3Users {
    padding: 5px !important;
}

.creditorsTittle {
    font-size: 14px;
}

.swML {
    margin-left: 90px;
}

.editCC {
    background: #73277C;
    color: #ffffff;
    border-radius: 50%;
    padding: 9px;
}

.editCC:hover {
    background: #790d79;
    color: #ffffff;
    border-radius: 50%;
    padding: 9px;
}

.form-signin select {
    width: 100%;
    height: 35px !important;
}

.form-signin input {
    width: 100%;
    height: 35px !important;
}

.btnLogin {
    height: 35px !important;
}

.cardsMenu {
    justify-content: start;
}

.textArticle {
    font-size: 13px;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.textArticleC h1,
h2,
h3,
h4,
h5 {
    font-size: 16px !important;
}

.textArticleC p {
    font-size: 14px !important;
}

.textArticle h1,
h2,
h3,
h4,
h5 {
    font-size: 16px !important;
}

.textArticle p {
    font-size: 14px !important;
}

.btnread {
    background: none !important;
    color: #5c1f9f !important;
    border: none !important;
}

.tablePayments .fa {
    display: none !important;
}

.multiS .multiselect-dropdown .dropdown-btn .selected-item-container {
    width: 100% !important;
}

.multiS .multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
    max-width: 94% !important;
}

.wi .multiselect-dropdown .dropdown-btn {
    padding: 1px 12px !important;
}

.muul .multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
    border: 1px solid #337ab7;
    margin-right: 4px;
    background: #337ab7;
    padding: 0 5px;
    color: #fff;
    border-radius: 2px;
    float: left;
    max-width: 210px !important;
}

.multiUsu .multiselect-dropdown {
    width: 25% !important;
}

.multi1 .multiselect-dropdown .dropdown-btn .selected-item-container {
    width: 100% !important;
}

.multi1 .multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
    max-width: 92% !important;
}

.multiUsu1 .multiselect-dropdown {
    width: 70% !important;
}

.multi2 .multiselect-dropdown .dropdown-btn .selected-item-container {
    width: 100% !important;
}


/* .multi2 .multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
    max-width: 100% !important;
} */


/*Estilos Generales*/

.tpa {
    top: 59px;
}

.btnBack {
    color: #5b2e7e;
    border-color: #5b2e7e !important;
    background: none;
    border-style: solid;
    border-width: 1px;
    border-top: 1px #5b2e7e;
    border-left: 1px;
    border-right: 1px;
}

#wrapper.toggled .btnBack {
    margin-left: -40px;
}

.btnEd {
    background-color: none !important;
    color: #5b2e7e;
    border-color: none !important;
    background: none;
    border: none;
}

.btnSave {
    background-color: #5b2e7e;
    border: none !important;
    color: white;
    border-radius: 5px;
}

.btnCancel {
    background-color: darkcyan;
    border: none !important;
    color: white;
    border-radius: 5px;
}

.center {
    text-align: center;
}

.textP {
    color: #5b2e7e;
}

.textP1 {
    color: #5b2e7e;
    margin: 0 auto;
}

.w-Src {
    width: 120px;
    max-width: 120px;
    min-width: 120px;
}

.fechS {
    width: 150px;
}


/*Botones*/

.btnArrow {
    color: white;
    background-color: #5b2e7e;
    border-radius: 10px;
    border: none;
    width: 40px;
    height: 40px;
}

.btnBackTop {
    color: white;
    font-size: 13px !important;
    background-color: #5b2e7e;
    border-radius: 4px;
    border: none;
    width: 25px;
    height: 25px;
    top: 0 !important;
    margin-left: 45px;
}

#wrapper.toggled .btnBackTop {
    margin-top: 20px;
    margin-left: 0px !important;
}

.positionTop {
    top: 0;
}

.slider.round-inn {
    border-radius: 25px;
    width: 53px;
    height: 27px;
}

input:checked+.slider {
    background-color: #9c68c8;
}

.slider.round-inn :before {
    border-radius: 60%;
    background: #5b2e7e;
}

.btnDropdown {
    background-color: #5b2e7e;
    border: none;
    color: aliceblue;
    text-align: center;
}

.changeDoc {
    background-color: #5b2e7e;
    border-radius: 5px;
    border: none;
    color: aliceblue;
    text-align: center;
    margin-left: 75px;
}

.cardDetail {
    height: 228px;
}

.btnChe {
    color: #14915f;
    background: none;
    border: none;
}

.btnClo {
    color: #686868;
    background: none;
    border: none;
}

.btnEdit {
    border: none;
    padding: 0;
    background: none;
    color: darkcyan;
}


/*canvas*/

.offcanvas-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem 1rem;
    flex-direction: row;
}

.badge-info {
    color: #fff;
    background-color: #0000006c;
}

.badge-success {
    color: #fff;
    background-color: #77dd77;
}

.btnRes {
    color: #c7f8e4;
    background: none;
    border: none;
}

.btnSta {
    color: #4a0053;
    background: none;
    border: none;
}

.select-css {
    color: rgb(121, 4, 132) !important;
    background-color: rgba(245, 217, 252, 0.39) !important;
    border-radius: 3px;
    border: none;
}

ngb-pagination ::ng-deep a {
    color: rgb(121, 4, 132) !important;
    background-color: rgba(245, 217, 252, 0.39) !important;
    border-color: none;
}

ngb-pagination ::ng-deep .page-item.active .page-link {
    border-color: #5ceef900;
    /* background-color: rgba(94, 251, 227, 0.409); */
    margin: 0 6px;
    border-radius: 3px;
}

ngb-pagination ::ng-deep .page-item.active .page-link:focus,
.page-link:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 0.1rem rgb(121, 4, 132);
}

ngb-pagination ::ng-deep .page-link:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 0.1rem #cf30ff00;
}

.cadBTNInfo {
    width: 300px;
    min-height: 230px;
}

@media (min-width: 1024px) and (max-width: 1200px) {
    .cadBTNInfo {
        width: 280px;
        min-height: 250px;
    }

    .justifyP {
        justify-content: start !important;
    }

    #wrapper.toggled .cadBTNInfo {
        width: 415px;
    }

    #wrapper.toggled .justifyP {
        justify-content: start !important;
    }
}

@media (max-width: 820px) {
    .cadBTNInfo {
        width: 390px;
    }

    .justifyP {
        justify-content: start !important;
    }

    #wrapper.toggled .cadBTNInfo {
        width: 473px;
    }
}

@media (min-width: 377px) and (max-width: 668px) {
    .cadBTNInfo {
        width: 314px;
    }
}

@media (max-width: 376px) {
    .cadBTNInfo {
        width: 348px;
    }
}

@media (min-width: 1181px) {}


/* Drag&Drop */

.custom-control-label::before,
.custom-file-label,
.custom-select {
    height: 30px !important;
    border: 1px solid #483871;
    padding: 0.175rem 1.75rem 0.175rem 0.75rem;
    width: 250px;
}

.dragAndDrop .custom-file-input {
    opacity: 1
}

.dragAndDrop span {
    top: -81px;
    font-size: 14px;
}

.dragAndDrop {
    margin-bottom: 0;
}

.dragAndDrop .form-control-file {
    position: relative;
    margin-top: 0;
    opacity: 0;
}

.dragAndDrop {
    border: 0.15em inset #655AA7 !important;
    border-radius: 6%;
    width: 300px;
    height: 200px;
}

.dragAndDrop:hover {
    border: 0.15em dashed #943D81 !important;
    border-radius: 6%;
    width: 300px;
    height: 200px;
}

.dragAndDrop.hover {
    border: 0.15em dashed red !important;
    border-radius: 6%;
    width: 300px;
    height: 200px;
}

.dragAndDrop .form-control-file {
    z-index: 10;
    position: relative;
    width: 100%;
    height: 200px;
    outline: none;
    cursor: pointer;
    color: #dadada !important;
    box-shadow: 0 0 5px solid currentColor;
}

.dragAndDrop .form-control-file:before {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    min-height: 2.9em;
    opacity: 1;
    visibility: visible;
    text-align: center;
    border: 0.10em dashed #655AA7;
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
    overflow: hidden;
}

.dragAndDrop .form-control-file:hover {
    border-style: solid;
    box-shadow: inset 0px 0px 0px 0.15em currentColor;
}

.dragAndDrop span {
    top: -120px;
    font-size: 14px;
    color: #655aa7;
}


/* MyTabs */

.colorTab {
    background: #cecece;
}

.nav-tabs .nav-link {
    border: 0px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.nav-link {
    color: rgba(153, 153, 153, 0.669);
    min-width: 95px;
}

.nav-link:focus,
.nav-link:hover {
    color: #894a8d;
    background-color: rgb(187 186 186);
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    border-color: none;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #73277C;
    background-color: #dcdcdc;
    border-top-color: #73277C;
    border-top-width: 2px;
}

.dragg {
    width: 364px;
}

.lgInp {
    width: 345px;
}

/* Dragula */

/* in-flight clone */
.gu-mirror {
    position: fixed !important;
    margin: 0 !important;
    z-index: 9999 !important;
    opacity: 0.8;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    filter: alpha(opacity=80);
    pointer-events: none;
}

/* high-performance display:none; helper */
.gu-hide {
    left: -9999px !important;
}

/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
}

/* added to the source element while its mirror is dragged */
.gu-transit {
    opacity: 0.2;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
    filter: alpha(opacity=20);
}

.noP {
    padding-right: 0;
    padding-left: 0;
    color: #9c68c8;
    border: none;
    padding: 0;
    background: none;
}

.buttonform {
    float: right;
    width: 90px;
    margin-left: 10px;
}